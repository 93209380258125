// Packages
import React from "react";
import { graphql } from "gatsby";

// Components
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	lautenschlagerLogo: {
		publicURL: string;
	};
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	return (
		<Layout
			location={location}
			title="AGB"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			noIndex
		>
			<Container>
				<div className="prose">
					<h1>AGB</h1>
					<p>
						Im folgenden informieren wir &uuml;ber die Erhebung personenbezogener Daten bei Nutzung unserer Website.
						Personenbezogene Daten sind alle Daten, die auf Sie pers&ouml;nlich beziehbar sind, zum Beispiel Name,
						Adresse, E-Mail-Adresse, Nutzungsverhalten.
					</p>
					<h2>&sect; I Allgemeines</h2>
					<p>
						(1) Die vorliegenden Gesch&auml;ftsbedingungen enthalten die zwischen Ihnen und Daniel Thiel,
						ausschlie&szlig;lich geltenden Bedingungen. Wir sind Ihr Vertragspartner. Die nachfolgenden Allgemeinen
						Gesch&auml;ftsbedingungen gelten f&uuml;r alle Lieferungen und Leistungen zwischen uns und einem Kunden
						(K&auml;ufer oder Seminarteilnehmer) in ihrer zum Zeitpunkt der Bestellung g&uuml;ltigen Fassung. Sie, die
						K&auml;uferinnen und K&auml;ufer und auch Seminarteilnehmerinnen und Seminarteilnehmer, nennen wir zur
						Erhaltung der Lesbarkeit in unseren Allgemeinen Gesch&auml;ftsbedingungen, &bdquo;Kunde&ldquo;.
					</p>
					<p>
						(2) Durch Abgabe einer Bestellung erkennt der K&auml;ufer die nachstehenden allgemeinen
						Gesch&auml;ftsbedingungen sowie die Liefer- und Zahlungsbedingungen an. Unsere Angebote hier finden Sie
						zurzeit lediglich in deutscher Sprache.
					</p>
					<h2>&sect; II Angebot und Vertragsabschluss von Waren</h2>
					<p>
						(1) Die folgenden Regelungen &uuml;ber den Vertragsabschluss gelten f&uuml;r Bestellungen und Buchungen
						&uuml;ber unseren Shop: <a href="https://www.daniel-thiel.com">www.daniel-thiel.com</a>
					</p>
					<p>(2) Im Falle des Vertragsschlusses kommt der Vertrag zustande mit: Daniel Thiel</p>
					<p>
						(3) Die Pr&auml;sentation der Waren in unserem Internetshop stellt kein rechtlich bindendes Vertragsangebot
						unsererseits dar, sondern ist nur eine unverbindliche Aufforderungen an den Verbraucher/ potentiellen
						Vertragspartner, Waren zu bestellen. Mit der Bestellung der gew&uuml;nschten Ware gibt der Verbraucher ein
						f&uuml;r ihn verbindliches Angebot auf Abschluss eines Kaufvertrages ab.
					</p>
					<p>(4) Eine Bestellung in unserem Shop erfolgt in folgenden Schritten:</p>
					<p>1) Auswahl der gew&uuml;nschten Ware</p>
					<p>2) Best&auml;tigen durch Anklicken der Buttons &bdquo;Zum Warenkorb hinzuf&uuml;gen&ldquo;</p>
					<p>3) Pr&uuml;fung der Angaben im Warenkorb</p>
					<p>4) Bet&auml;tigung des Buttons &bdquo;Zum Checkout&ldquo;</p>
					<p>
						5) Anmeldung im Internetshop nach Registrierung und Eingabe der Anmelderangaben (E-Mail-Adresse und
						Passwort).
					</p>
					<p>6) Nochmalige Pr&uuml;fung bzw. Berichtigung der jeweiligen eingegebenen Daten.</p>
					<p>7) Verbindliche Absendung der Bestellung.</p>
					<p>
						Der Verbraucher kann vor dem verbindlichen Absenden der Bestellung durch Bet&auml;tigen der in dem von ihm
						verwendeten Internet-Browser enthaltenen &bdquo;Zur&uuml;ck&ldquo;-Taste nach Kontrolle seiner Angaben
						wieder zu der Internetseite gelangen, auf der die Angaben des Kunden erfasst werden und Eingabefehler
						berichtigen bzw. durch Schlie&szlig;en des Internetbrowsers den Bestellvorgang abbrechen. Wir
						best&auml;tigen den Eingang der Bestellung durch eine automatisch generierte E-Mail
						(&bdquo;Auftragsbest&auml;tigung&ldquo;). Mit dieser nehmen wir Ihr Angebot an.
					</p>
					<p>
						(5) F&uuml;r den Umfang der vertraglich geschuldeten Leistung ist ausschlie&szlig;lich die
						Auftragsbest&auml;tigung des Verk&auml;ufers ma&szlig;gebend
					</p>
					<p>
						(6) Speicherung des Vertragstextes bei Bestellungen &uuml;ber unseren Internetshop: Wir senden Ihnen die
						Bestelldaten und unsere AGB per E-Mail zu. Die AGB k&ouml;nnen Sie jederzeit auch unter www.daniel-thiel.com
						einsehen. Ihre Bestelldaten sind aus Sicherheitsgr&uuml;nden nicht mehr &uuml;ber das Internet
						zug&auml;nglich.
					</p>
					<p>
						(7) Die dem Angebot oder der Auftragsbest&auml;tigung zugrunde liegenden Unterlagen wie Abbildungen,
						Zeichnungen, Ma&szlig;- und Gewichtsangaben sind in der Regel nur als Ann&auml;herungswerte zu verstehen,
						sofern sie nicht ausdr&uuml;cklich als verbindlich bezeichnet werden. Bildliche Darstellungen k&ouml;nnen
						Dekorationen enthalten, die nicht Vertragsbestandteil sind.
					</p>
					<h2>&sect;&nbsp;1&nbsp;Eigentumsvorbehalt und Kaufpreiszahlung</h2>
					<p>(1) Die Ware bleibt bis zur vollst&auml;ndigen Bezahlung unser Eigentum.</p>
					<p>(2) Der K&auml;ufer ist zur Sicherungs&uuml;bereignung oder Verpf&auml;ndung der Ware nicht befugt</p>
					<p>
						(3) Der K&auml;ufer hat die Wahl, ob der Versand per karte, Klarna, Vorkasse, SEPA-Lastschriftmandat oder
						per Rechnung erfolgen soll. Unabh&auml;ngig von der Anzahl der bestellten&nbsp;Artikel tr&auml;gt der
						K&auml;ufer die Kosten f&uuml;r den versicherten Versand. Bei Vorkasse hat die Zahlung des Kaufpreises
						ausschlie&szlig;lich auf das in der Kaufabwicklung dem K&auml;ufer mitgeteilte Konto zu erfolgen.
					</p>
					<p>
						(4) Sofern nichts anderes vereinbart wird, ist der Kaufpreis innerhalb von 10 Tagen nach Rechnungsstellung
						in der Kaufabwicklung zahlbar. Verzug tritt gem. &sect; 286 Abs. 3 BGB sp&auml;testens in 30 Tagen nach
						F&auml;lligkeit und Zugang der Rechnung ein, wenn dem K&auml;ufer nicht zuvor eine Mahnung zugegangen ist.
						Verzugszinsen werden f&uuml;r den Fall, dass der K&auml;ufer die Nichtleistung zu vertreten hat, in
						H&ouml;he von f&uuml;nf Prozentpunkten j&auml;hrlich &uuml;ber dem jeweiligen Basiszinssatz berechnet. Die
						Geltendmachung eines h&ouml;heren Verzugsschadens bleibt vorbehalten.
					</p>
					<p>(5) Die M&ouml;glichkeit des Skontoabzugs besteht nicht</p>
					<p>
						(6) F&uuml;r den Fall der Nichteinl&ouml;sung oder R&uuml;ckgabe einer Lastschrift erm&auml;chtigt der
						K&auml;ufer hiermit unwiderruflich seine Bank, seinen Namen und seine aktuelle Anschrift an uns mitzuteilen.
						M&ouml;gliche R&uuml;cklastschriftgeb&uuml;hren, die uns aus vom K&auml;ufer zu vertretenden Gr&uuml;nden in
						Rechnung gestellt wurden, k&ouml;nnen wir vom K&auml;ufer einfordern.
					</p>
					<h2>&sect; 2 Lieferbedingungen und Versandkosten</h2>
					<p>
						(1) Wir liefern die Ware gem&auml;&szlig; den mit Ihnen getroffenen Vereinbarungen. Alle Preise sind
						inklusive der gesetzlichen Mehrwertsteuer angegeben. Die Versandkosten sind nicht im Kaufpreis enthalten.
						Wir weisen darauf hin, dass bei einem Versand ins Ausland eventuell h&ouml;here Versandkosten, Z&ouml;lle
						und Geb&uuml;hren anfallen k&ouml;nnen.
					</p>
					<p>
						(2) Die Ware wird nach erfolgter Gutschrift des Gesamtbetrages innerhalb von 3 Werktagen an die angegebene
						Anschrift versandt, wenn nicht ausdr&uuml;cklich eine andere Lieferzeit bzw. Lieferanschrift verbindlich
						vereinbart wird. Die Gefahr des zuf&auml;lligen Untergangs oder einer zuf&auml;lligen Verschlechterung der
						Kaufsache geht in dem Zeitpunkt auf den Besteller &uuml;ber, in dem dieser die Ware in Empfang nimmt oder in
						Annahmeverzug ger&auml;t. Soweit eine Lieferung an eine abweichende Lieferanschrift erfolgen soll, muss der
						K&auml;ufer diese Adresse per E-Mail an{" "}
						<a href="mailto:anfragen@daniel-thiel.com">anfragen@daniel-thiel.com</a> mitteilen. Weitere
						Lieferzeitangaben entnehmen Sie bitte den Angeboten.
					</p>
					<p>
						(3) Der Warenversand erfolgt ausschlie&szlig;lich &uuml;ber die Deutsche Post AG/ DHL. Die Geb&uuml;hren
						hierf&uuml;r betragen innerhalb Deutschlands f&uuml;r&nbsp;versicherte Pakete f&uuml;r den ersten Artikel
						6,70 Euro.
					</p>
					<p>
						(4) Teillieferungen sind zul&auml;ssig, soweit nicht der K&auml;ufer erkennbar kein Interesse an ihnen hat
						oder ihm diese erkennbar nicht zumutbar sind. Der K&auml;ufer hat lediglich die Versandkosten zu tragen, die
						im Falle der Gesamtlieferung der bestellten Ware angefallen w&auml;ren.
					</p>
					<p>
						(5) Soweit Versand in das Ausland angeboten wird, berechnen wir&nbsp;ausschlie&szlig;lich die
						tats&auml;chlich anfallenden Portokosten der Deutschen Post AG/ DHL. Diese Kosten sind f&uuml;r uns ohne
						Kenntnis des Ziellandes, des Gewichts und der Abmessungen des erforderlich werdenden Pakets bzw.
						P&auml;ckchens, sowie der von Ihnen gew&uuml;nschten Versandart usw. nicht bestimmbar. Sie haben deshalb -
						auch vor einem eventuellen Vertragsschluss - die M&ouml;glichkeit, die jeweiligen Auslandskosten unter
						Angabe der individuellen Einzelheiten bei&nbsp;uns direkt zu erfragen. Des Weiteren k&ouml;nnen Sie die
						Kosten auch online &uuml;ber den "Portokalkulator" der Deutschen Post AG in Erfahrung bringen.
					</p>
					<p>Den Portokalkulator der Deutschen Post erreichen Sie &uuml;ber den folgenden Link:</p>
					<p>
						<a href="https://www.deutschepost.de/de/p/portokalkulator.html">Portokalkulator Deutsche Post AG</a>
					</p>
					<p>(6) Kauf von digitalen Waren wie E-Books und H&ouml;rb&uuml;cher zum Download:</p>
					<p>
						Die Lieferung der digitalen Waren zum Download erfolgt per Download von der Webseite{" "}
						<a href="https://www.daniel-thiel.com">www.daniel-thiel.com</a> Sie ist sofort nach Kaufabwicklung
						m&ouml;glich.
					</p>
					<p>
						(7) In Ausnahmef&auml;llen ist nach vorheriger schriftlicher Vereinbarung mit uns eine Versendung per E-Mail
						an die vom Kunden angegebene E-Mail-Adresse m&ouml;glich. Bei der Abwicklung der Transaktion ist
						ausschlie&szlig;lich die in der Bestellabwicklung bei uns angegebene E-Mail-Adresse ma&szlig;geblich.
						F&uuml;r Schreib- und &Uuml;bermittlungsfehler wird keine Haftung &uuml;bernommen.
					</p>
					<h2>&sect; 3 Lieferfrist</h2>
					<p>
						(1) Das Einhalten einer Lieferfrist ist immer von der rechtzeitigen Selbstbelieferung abh&auml;ngig.
						H&auml;ngt die Lieferm&ouml;glichkeit von der Belieferung durch einen Vorlieferanten ab und scheitert diese
						Belieferung aus Gr&uuml;nden, die wir nicht zu vertreten haben, so sind wir zum R&uuml;cktritt vom Vertrag
						berechtigt. Dem K&auml;ufer steht ein Recht auf Schadensersatz aus diesem Grunde dann nicht zu.
					</p>
					<p>
						(2) Gleiches gilt, wenn aufgrund von h&ouml;herer Gewalt oder anderen Ereignissen die Lieferung wesentlich
						erschwert oder unm&ouml;glich wird und wir dies nicht zu vertreten haben. Zu solchen Ereignissen z&auml;hlen
						insbesondere: Feuer, &Uuml;berschwemmung, Arbeitskampf, Betriebsst&ouml;rungen, Streik und beh&ouml;rdliche
						Anordnungen, die nicht unserem Betriebsrisiko zuzurechnen sind.
					</p>
					<p>
						(3) Der K&auml;ufer wird in den genannten F&auml;llen unverz&uuml;glich &uuml;ber die fehlende
						Lieferm&ouml;glichkeit unterrichtet und eine bereits erbrachte Leistung wird unverz&uuml;glich erstattet.
					</p>
					<h2>&sect; 4 R&uuml;cksendekosten</h2>
					<p>
						&Uuml;bt der K&auml;ufer, der Verbraucher im Sinne des Gesetzes ist, sein Widerrufsrecht nach der
						Ma&szlig;gabe des nachstehenden &sect; 8 unserer Allgemeinen Gesch&auml;ftsbedingungen aus, so werden ihm
						die R&uuml;cksendekosten auferlegt, wenn die gelieferte Ware der bestellten entspricht und wenn der Preis
						der zur&uuml;ckzusendenden Sache einen Betrag von 40 Euro nicht &uuml;bersteigt oder, wenn der Besteller bei
						einem h&ouml;heren Preis der Sache noch nicht die Teilzahlung erbracht hat. Anderenfalls ist die
						R&uuml;cksendung f&uuml;r den K&auml;ufer kostenfrei.
					</p>
					<h2 id="widerrufsrecht">
						&sect; 5 Widerrufsrecht (ausschlie&szlig;lich f&uuml;r Verbraucher im Sinne des Gesetzes)
					</h2>
					<p>
						Sie k&ouml;nnen Ihre Vertragserkl&auml;rung innerhalb von 14 Tagen ohne Angabe von Gr&uuml;nden in Textform
						(z. B. Brief, Fax, E-Mail) oder &ndash; wenn Ihnen die Sache vor Fristablauf &uuml;berlassen wird &ndash;
						auch durch R&uuml;cksendung der Sache widerrufen. Die Frist beginnt nach Erhalt dieser Belehrung in
						Textform, jedoch nicht vor Eingang der Ware beim Empf&auml;nger (bei der wiederkehrenden Lieferung
						gleichartiger Waren nicht vor Eingang der ersten Teillieferung) und auch nicht vor Erf&uuml;llung unserer
						Informationspflichten gem&auml;&szlig; Artikel 246 &sect; 2 in Verbindung mit &sect; 1 Abs. 1 und 2 EGBGB
						sowie unserer Pflichten gem&auml;&szlig; &sect; 312g Abs. 1 Satz 1 BGB in Verbindung mit Artikel 246 &sect;
						3 EGBGB. Zur Wahrung der Widerrufsfrist gen&uuml;gt die rechtzeitige Absendung des Widerrufs oder der Sache.
					</p>
					<p>Der Widerruf ist zu richten an:</p>
					<p>Daniel Thiel</p>
					<p>Anschrift: Friedrich-Ebert-Straße 143, 34119 Kassel</p>
					<p>E-Mail: anfragen@daniel-thiel.com</p>
					<p>Widerrufsfolgen</p>
					<p>
						Im Falle eines wirksamen Widerrufs sind die beiderseits empfangenen Leistungen zur&uuml;ckzugew&auml;hren
						und ggf. gezogene Nutzungen (z. B. Zinsen) herauszugeben. K&ouml;nnen Sie uns die empfangene Leistung sowie
						Nutzungen (z. B. Gebrauchsvorteile) nicht oder teilweise nicht oder nur in verschlechtertem Zustand
						zur&uuml;ckgew&auml;hren beziehungsweise herausgeben, m&uuml;ssen Sie uns insoweit Wertersatz leisten.
						F&uuml;r die Verschlechterung der Sache und f&uuml;r gezogene Nutzungen m&uuml;ssen Sie Wertersatz nur
						leisten, soweit die Nutzungen oder die Verschlechterung auf einen Umgang mit der Sache
						zur&uuml;ckzuf&uuml;hren ist, der &uuml;ber die Pr&uuml;fung der Eigenschaften und der Funktionsweise
						hinausgeht. Unter &bdquo;Pr&uuml;fung der Eigenschaften und der Funktionsweise&ldquo; versteht man das
						Testen und Ausprobieren der jeweiligen Ware, wie es etwa im Ladengesch&auml;ft m&ouml;glich und &uuml;blich
						ist.
					</p>
					<p>
						Paketversandf&auml;hige Sachen sind auf unsere Gefahr zur&uuml;ckzusenden. Sie haben die
						regelm&auml;&szlig;igen Kosten der R&uuml;cksendung zu tragen, wenn die gelieferte Ware der bestellten
						entspricht und wenn der Preis der zur&uuml;ckzusendenden Sache einen Betrag von 40 Euro nicht
						&uuml;bersteigt oder wenn Sie bei einem h&ouml;heren Preis der Sache zum Zeitpunkt des Widerrufs noch nicht
						die Gegenleistung oder eine vertraglich vereinbarte Teilzahlung erbracht haben. Anderenfalls ist die
						R&uuml;cksendung f&uuml;r Sie kostenfrei. Nicht paketversandf&auml;hige Sachen werden bei Ihnen abgeholt.
						Verpflichtungen zur Erstattung von Zahlungen m&uuml;ssen innerhalb von 30 Tagen erf&uuml;llt werden. Die
						Frist beginnt f&uuml;r Sie mit der Absendung Ihrer Widerrufserkl&auml;rung oder der Sache, f&uuml;r uns mit
						deren Empfang.
					</p>
					<p>Ende der Widerrufsbelehrung</p>
					<p>
						Von dieser M&ouml;glichkeit des Widerrufs ausgenommen sind Waren, die aufgrund Ihrer Beschaffenheit nicht
						f&uuml;r eine R&uuml;cksendung geeignet sind oder schnell verderben k&ouml;nnen (&sect;312 d Abs. 4 Nr.1
						BGB).
					</p>
					<p>Das Widerrufsrecht besteht nicht bei Fernabsatzvertr&auml;gen</p>
					<p>- zur Lieferung von Waren, die nach Kundenspezifikation angefertigt werden</p>
					<p>oder</p>
					<p>- eindeutig auf die pers&ouml;nlichen Bed&uuml;rfnisse zugeschnitten sind</p>
					<p>oder</p>
					<p>- die auf Grund ihrer Beschaffenheit nicht f&uuml;r eine R&uuml;cksendung geeignet sind</p>
					<p>oder</p>
					<p>- schnell verderben k&ouml;nnen</p>
					<p>oder</p>
					<p>- deren Verfallsdatum &uuml;berschritten w&uuml;rde,</p>
					<p>- zur Lieferung von Audio- oder Videoaufzeichnungen</p>
					<p>oder</p>
					<p>von Software, sofern die gelieferten Datentr&auml;ger vom Verbraucher entsiegelt worden&nbsp;sind oder</p>
					<p>- zur Lieferung von Zeitungen, Zeitschriften und Illustrierten.</p>
					<p>
						Das Recht auf Widerruf des Vertrages besteht unabh&auml;ngig von Ihren Anspr&uuml;chen auf
						Gew&auml;hrleistung, sofern die Ware Fehler aufweist. Sofern ein gew&auml;hrleistungspflichtiger Mangel
						vorliegt, sind Sie im Rahmen der gesetzlichen Bestimmungen berechtigt, Nacherf&uuml;llung zu verlangen, von
						dem Vertrag zur&uuml;ckzutreten oder den Kaufpreis zu mindern.
					</p>
					<h2>&sect; 6 Vertragliche Regelung der R&uuml;cksendekosten im Falle des Widerrufs</h2>
					<p>
						(1) Sollten Sie von Ihrem Widerrufsrecht Gebrauch machen, so gilt im Einklang mit &sect; 357 Abs. 2 BGB,
						folgende Vereinbarung, nach der Sie die regelm&auml;&szlig;igen Kosten der R&uuml;cksendung zu tragen haben,
						wenn die gelieferte Ware der bestellten entspricht und wenn der Preis der zur&uuml;ckzusendenden Sache einen
						Betrag von 40 Euro nicht &uuml;bersteigt oder wenn Sie bei einem h&ouml;heren Preis der Sache zum Zeitpunkt
						des Widerrufs noch nicht die Gegenleistung oder eine vertraglich vereinbarte Teilzahlung erbracht haben.
					</p>
					<p>(2) Anderenfalls ist die R&uuml;cksendung f&uuml;r Sie kostenfrei.</p>
					<h2>&sect; 7 Gew&auml;hrleistung</h2>
					<p>
						Die Gew&auml;hrleistungsanspr&uuml;che richten sich nach den gesetzlichen Bestimmungen.
						Gew&auml;hrleistungsanspr&uuml;che oder etwaige Beschwerden k&ouml;nnen Sie an die im Impressum genannte
						Anschrift richten.
					</p>
					<h2>&sect; III Angebot und Vertragsabschluss von Dienstleistungen</h2>
					<h2>&sect; 1 Freibleibende Angebote</h2>
					<p>
						(1) Die Angebote sind freibleibend, &Auml;nderungen bleiben vorbehalten. Alle Honorare verstehen sich in
						Euro und inkl. gesetzlicher Mehrwertsteuer.
					</p>
					<p>
						(2) Die auf der Webseite <a href="https://www.daniel-thiel.com">www.daniel-thiel.com</a> aufrufbaren Seiten
						mit Produkt- und Seminarangeboten stellen noch kein Angebot zum Abschluss eines Vertrages, sondern lediglich
						eine Aufforderung zur Abgabe einer Bestellung (Angebot) durch den Kunden dar.
					</p>
					<h2>&sect; 2 Anmeldung</h2>
					<p>(1) Anmeldungen m&uuml;ssen schriftlich, telefonisch oder per e-Mail erfolgen.</p>
					<p>
						(2) Nach Eingang der Anmeldung erh&auml;lt der Auftraggeber per e-Mail eine Auftragsbest&auml;tigung mit
						Termin, Veranstaltungsort, Veranstaltungszeiten und den vereinbarten Kosten zugesandt.
					</p>
					<p>
						(3) Diese Best&auml;tigung f&uuml;hrt den Vertragsschluss zwischen dem Seminarteilnehmer oder dem
						Coachingteilnehmer (nachfolgend &bdquo;Seminarteilnehmer&ldquo; genannt) herbei.
					</p>
					<h2>&sect; 3 Teilnahmegeb&uuml;hr und F&auml;lligkeit</h2>
					<p>
						(1) Die Teilnahmegeb&uuml;hren entnehmen Sie bitte den jeweiligen Angeboten auf der Website
						www.daniel-thiel.com Diese verstehen sich in Euro und inklusive der gesetzlichen Mehrwertsteuer.
					</p>
					<p>
						(2) Die Teilnahmegeb&uuml;hren sind grunds&auml;tzlich nach Erhalt der Rechnung in dem dort angegebenen
						Zahlungsziel zur Zahlung f&auml;llig.
					</p>
					<h2>&sect; 4 R&uuml;cktritt</h2>
					<p>(1) Der Seminarteilnehmer kann grunds&auml;tzlich jederzeit von dem Vertrag zur&uuml;cktreten.</p>
					<p>(2) R&uuml;cktritte m&uuml;ssen stets schriftlich erfolgen.</p>
					<p>
						(3) Bei Abmeldung bis vier Wochen vor Seminarbeginn werden dem Auftraggeber 50% der vereinbarten Honorare in
						Rechnung gestellt.
					</p>
					<p>
						(4) Bei Abmeldung von weniger als vier bis zwei Wochen vor dem Seminar werden 75% der vereinbarten Honorare
						in Rechnung gestellt.
					</p>
					<p>
						(5) Bei Abmeldung innerhalb der letzten 2 Wochen vor der Veranstaltung werden dem Auftraggeber 100% der
						vereinbarten der vereinbarten Honorare in Rechnung gestellt.
					</p>
					<p>
						(6) Nimmt der Auftraggeber nicht die volle Leistung in Anspruch, so besteht f&uuml;r den nicht genutzten
						Teil kein R&uuml;ckverg&uuml;tungsanspruch.
					</p>
					<p>
						(7) Die Teilnahme ist nicht &uuml;bertragbar. Kosten f&uuml;r Fremdleistungen gehen grunds&auml;tzlich zu
						Lasten des Auftraggebers.
					</p>
					<h2>&sect; 5 Absage</h2>
					<p>
						(1) Daniel Thiel beh&auml;lt sich das Recht vor, anstehende noch nicht begonnene Seminare und bei zu
						geringer Teilnehmerzahl (in der Regel weniger als sechs Teilnehmer) bis zu drei Tage vor Seminarbeginn
						abzusagen oder Ersatztermine anzubieten.
					</p>
					<p>(2) Im Falle der Absage werden die geleisteten Zahlungen erstattet.</p>
					<p>
						(3) Bei h&ouml;herer Gewalt, insbesondere Unwetter, Erdbeben, &Uuml;berschwemmungen oder Streiks oder
						sonstigen unvorhersehbaren Ereignissen besteht kein Anspruch auf Durchf&uuml;hrung. Ein Anspruch auf Ersatz
						von Reise- und &Uuml;bernachtungskosten sowie Arbeitsausfall besteht nicht. F&uuml;r mittelbare
						Sch&auml;den, insbesondere entgangene Gewinn oder Anspr&uuml;che Dritter wird nicht gehaftet.
					</p>
					<h2>&sect; 6 Ausschluss</h2>
					<p>
						(1) Daniel Thiel beh&auml;lt sich das Recht vor, Seminarteilnehmer und/oder ihre G&auml;ste zu bitten, das
						Seminar oder die Seminarr&auml;ume sofort zu verlassen, sollten diese Personen das Seminar nachhaltig
						st&ouml;ren oder sich unprofessionell verhalten oder unter dem Einfluss von Alkohol oder unter dem Einfluss
						illegaler Substanzen stehen oder im Besitz von Alkohol und/oder illegalen Substanzen sein.
					</p>
					<p>
						(2) In einem solchen Fall des schuldhaften Ausschlusses werden die Seminargeb&uuml;hren nicht erstattet.
					</p>
					<p>
						(3) Daniel Thiel beh&auml;lt sich das Recht vor, f&uuml;r diesen Fall Schadensersatzanspr&uuml;che, geltend
						zu machen.
					</p>
					<h2>&sect; 7 Urheberrechte</h2>
					<p>
						Alle an den Seminarteilnehmer ausgeh&auml;ndigten Unterlagen sind, soweit nichts anderes vereinbart ist, in
						der vereinbarten Verg&uuml;tung enthalten. Das Urheberrecht an den Unterlagen geh&ouml;rt allein der GbR
						Feder Fabian, F&ouml;rster Johannes. Dem Seminarteilnehmer ist es nicht gestattet, die Unterlagen ohne
						schriftliche Zustimmung von GbR Feder Fabian, F&ouml;rster Johannes ganz oder auszugsweise zu reproduzieren
						zu ver&ouml;ffentlichen und/ oder ver&ouml;ffentlichen zu lassen und/oder Dritten zug&auml;nglich zu machen.
					</p>
					<h2>&sect; 8 Belastbarkeit der Teilnehmer und m&ouml;gliche Ausschl&uuml;sse</h2>
					<p>
						Jeder Seminarteilnehmer tr&auml;gt die volle Verantwortung f&uuml;r sich und seine Handlungen innerhalb und
						au&szlig;erhalb der Coaching-Sitzungen und kommt f&uuml;r eventuell verursachte Sch&auml;den selbst auf. Das
						Coaching ist keine Psychotherapie und kann diese nicht ersetzen. Die Teilnahme setzt eine normale psychische
						und physische Belastbarkeit voraus.
					</p>
					<h2>&sect; 9 Haftungsausschluss</h2>
					<p>(1) Haftungsausschluss f&uuml;r Personen- und Sachsch&auml;den</p>
					<ol>
						<li>
							GbR Feder Fabian, F&ouml;rster Johannes - haftet nicht f&uuml;r Personen- und Sachsch&auml;den, die
							Seminarteilnehmer vor, w&auml;hrend oder nach dem Seminar, in oder vor dem Seminarraum oder einem anderen
							Veranstaltungsort selbstverschuldet oder durch einen anderen Seminarteilnehmer oder durch einen Dritten
							erleiden.
						</li>
						<li>
							Dieser Haftungsausschluss findet keine Anwendung f&uuml;r Sch&auml;den aus der Verletzung des Lebens, des
							K&ouml;rpers oder der Gesundheit, die auf einer fahrl&auml;ssigen Pflichtverletzung der GbR Feder Fabian,
							F&ouml;rster Johannes - oder einer vors&auml;tzlichen oder fahrl&auml;ssigen Pflichtverletzung eines
							gesetzlichen Vertreters oder Erf&uuml;llungsgehilfen der GbR Feder Fabian, F&ouml;rster Johannes -
							beruhen.
						</li>
						<li>
							Zudem findet dieser Haftungsausschluss keine Anwendung f&uuml;r sonstige Sch&auml;den, die auf einer grob
							fahrl&auml;ssigen Pflichtverletzung von GbR Feder Fabian, F&ouml;rster Johannes - oder auf einer
							vors&auml;tzlichen oder grob fahrl&auml;ssigen Pflichtverletzung eines gesetzlichen Vertreters oder
							Erf&uuml;llungsgehilfen der GbR Feder Fabian, F&ouml;rster Johannes - beruhen.
						</li>
					</ol>
					<p>(2) Besonderer Haftungsausschluss &ndash; Keine professionelle Beratung</p>
					<p>
						Die Informationen und Ratschl&auml;ge in Coaching-Sitzungen oder Seminaren sowie in allen Dokumentationen
						sind durch die GbR Feder Fabian, F&ouml;rster Johannes - sorgf&auml;ltig erwogen und gepr&uuml;ft. Bei der
						T&auml;tigkeit von GbR Feder Fabian, F&ouml;rster Johannes - handelt es sich um eine reine
						Dienstleistungst&auml;tigkeit. Ein Erfolg ist daher nicht geschuldet. Eine Haftung wird ausgeschlossen
					</p>
					<p>
						(3) Der Seminarteilnehmer ist allein f&uuml;r seine Entscheidungen, Ma&szlig;nahmen und Ergebnisse im Leben
						verantwortlich. Die GbR Feder Fabian, F&ouml;rster Johannes - kann nicht daf&uuml;r haftbar gemacht werden,
						wenn der Seminarteilnehmer Entscheidungen trifft und/ oder Handlungen in seinem Leben oder seinen
						Gesch&auml;ftsprozessen vornimmt, die auf der Seminarteilnahme basieren. Informationen, die durch die GbR
						Feder Fabian, F&ouml;rster Johannes - w&auml;hrend der Seminare weitergegeben werden, k&ouml;nnen die
						Dienstleistungen von ausgebildeten Fachleuten in allen Bereichen, insbesondere in den Bereichen der,
						Finanz-, medizinischen, psychologische oder rechtliche Beratung nicht ersetzen.
					</p>
					<h2>&sect; 10 Foto-, Ton- und Videoaufnahmen</h2>
					<p>
						Die Anfertigung von Foto-, Ton- und Videoaufnahmen w&auml;hrend der Seminare ist ohne vorherige schriftliche
						Einwilligung nicht gestattet.
					</p>
					<h2>&sect; IV Datenschutz</h2>
					<p>
						(1) Wir verwenden die von Ihnen mitgeteilten personenbezogenen Daten gem&auml;&szlig; den Bestimmungen der
						Datenschutzgrundverordnung. Einzelheiten k&ouml;nnen Sie unserer Datenschutzerkl&auml;rung entnehmen.
					</p>
					<p>(2) Insbesondere gilt, dass</p>
					<ol>
						<li>
							Ihre personenbezogenen Daten, soweit diese f&uuml;r die Begr&uuml;ndung, inhaltliche Ausgestaltung oder
							&Auml;nderung des Vertragsverh&auml;ltnisses erforderlich sind (Bestandsdaten), ausschlie&szlig;lich zur
							Abwicklung der zwischen uns abgeschlossenen Kaufvertr&auml;ge verwendet werden, etwa zur Zustellung von
							Waren an die von Ihnen angegebene Adresse. Eine dar&uuml;ber hinausgehende Nutzung Ihrer Bestandsdaten
							f&uuml;r Zwecke der Werbung, der Marktforschung oder zur bedarfsgerechten Gestaltung unserer Angebote
							bedarf Ihrer ausdr&uuml;cklichen Einwilligung. Sie haben die M&ouml;glichkeit, diese Einwilligung vor
							Erkl&auml;rung Ihrer Bestellung zu erteilen. Diese Einwilligungserkl&auml;rung erfolgt v&ouml;llig
							freiwillig und kann von Ihnen jederzeit widerrufen werden.
						</li>
						<li>
							Ihre personenbezogenen Daten, welche erforderlich sind, um die Inanspruchnahme unserer Angebote zu
							erm&ouml;glichen und abzurechnen (Nutzungsdaten), ebenfalls ausschlie&szlig;lich zur Abwicklung der
							zwischen uns abgeschlossenen Kaufvertr&auml;ge verwendet werden.&nbsp;
						</li>
					</ol>
					<h2>&sect; V Schlussbestimmungen</h2>
					<p>(1) Vertragssprache ist Deutsch.</p>
					<p>
						(2) Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Unber&uuml;hrt
						bleiben zwingende Bestimmungen des Staates, in dem Sie Ihren gew&ouml;hnlichen Aufenthalt haben.
					</p>
					<p>
						(3) Ist der Kunde Kaufmann, juristische Person des &ouml;ffentlichen Rechts oder &ouml;ffentlich-rechtliches
						Sonderverm&ouml;gen, ist ausschlie&szlig;licher Gerichtsstand f&uuml;r alle Streitigkeiten aus diesem
						Vertrag der Gesch&auml;ftssitz der GbR Feder Fabian, F&ouml;rster Johannes. Dasselbe gilt, wenn der Kunde
						keinen allgemeinen Gerichtsstand in Deutschland oder der EU hat oder Wohnsitz oder gew&ouml;hnlicher
						Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt sind. Die Befugnis, auch das Gericht an einem
						anderen gesetzlichen Gerichtsstand anzurufen bleibt hiervon unber&uuml;hrt.
					</p>
					<p>
						(4) Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder den gesetzlichen Regelungen
						widersprechen, so wird hierdurch der Vertrag im &Uuml;brigen nicht ber&uuml;hrt. Die unwirksamke Bestimmung
						wird von den Vertragsparteien einvernehmlich durch eine solche Bestimmung ersetzt, welche dem
						wirtschaftlichen Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise am n&auml;chsten kommt.
						Die vorstehende Regelung gilt entsprechend bei Regelungsl&uuml;cken.
					</p>
					<p>Anhang</p>
					<p>Verbraucherinformation bei Fernabsatzvertr&auml;gen &uuml;ber den Erwerb von Waren</p>
					<p>
						Soweit Sie beim Besuch unseres Onlineshops Waren erwerben, die weder ihrer gewerblichen noch ihrer
						selbstst&auml;ndigen beruflichen T&auml;tigkeit zugerechnet werden k&ouml;nnen, m&ouml;chten wir Sie auf
						folgendes hinweisen:
					</p>
					<p>
						(1) Die f&uuml;r den Vertragsabschluss zur Verf&uuml;gung stehende Sprache ist ausschlie&szlig;lich Deutsch.
					</p>
					<p>
						(2) Die wesentlichen Merkmale der von uns angebotenen Waren sowie die G&uuml;ltigkeitsdauer befristeter
						Angebote entnehmen Sie bitte den einzelnen Produktbeschreibungen im Rahmen des Internetangebots.
					</p>
					<p>
						(3) Alle Preise sind inklusive der gesetzlichen Mehrwertsteuer angegeben. Die Versandkosten sind nicht im
						Preis enthalten.
					</p>
					<p>
						(4) Sofern nichts anderes vereinbart wird, ist der Kaufpreis innerhalb von 10 Tagen nach Rechnungsstellung
						in der automatisierten Kaufabwicklung zahlbar. Verzugszinsen werden f&uuml;r den Fall, dass der K&auml;ufer
						die Nichtleistung zu vertreten hat, in H&ouml;he von f&uuml;nf Prozentpunkten j&auml;hrlich &uuml;ber dem
						jeweiligen Basiszinssatz berechnet. Die Geltendmachung eines h&ouml;heren Verzugsschadens bleibt
						vorbehalten.
					</p>
					<p>
						(5) Sie haben ein Widerrufsrecht, insoweit verweisen wir auf unsere allgemeinen Gesch&auml;ftsbedingungen
						(s.o.).
					</p>
					<p>Hinweis</p>
					<p>
						Sie k&ouml;nnen diese AGB ausdrucken oder speichern, indem Sie die &uuml;bliche Funktionalit&auml;t Ihres
						Internetdienstprogramms (Browser) nutzen. Regelm&auml;&szlig;ig findet sich im Men&uuml; unter "Datei" eine
						Option zur Speicherung und zum Druck der aufgerufenen Internetseite. In schriftlicher Form erhalten Sie die
						Allgemeinen Gesch&auml;ftsbedingungen auch mit der Warensendung.
					</p>
					<p>Anbieterkennung:</p>
					<p>Daniel Tiel</p>
					<p>E-Mail: anfragen@daniel-thiel.com</p>
					<p>Hinweise zur Verpackungsverordnung:</p>
					<p>
						Gem&auml;&szlig; der Regelungen der Verpackungsverordnung sind wir dazu verpflichtet, Verpackungen unserer
						Produkte, die nicht das Zeichen eines Systems der fl&auml;chendeckenden Entsorgung (wie etwa dem
						"Gr&uuml;nen Punkt" des Dualen System Deutschland AG) tragen, zur&uuml;ckzunehmen und f&uuml;r deren
						Wiederverwendung oder Entsorgung zu sorgen.
					</p>
					<p>
						Zur weiteren Kl&auml;rung der R&uuml;ckgabe setzen Sie sich bei solchen Produkten bitte mit uns in
						Verbindung:
					</p>
					<p>Daniel Thiel</p>
					<p>
						E-Mail: <a href="mailto:anfragen@daniel-thiel.com">anfragen@daniel-thiel.com</a>
					</p>
					<p>Telefon: 0561 510 5 999 0</p>
					<p>
						Wir nennen Ihnen dann eine kommunale Sammelstelle oder ein Entsorgungsunternehmen in Ihrer N&auml;he, das
						die Verpackungen kostenfrei entgegennimmt. Sollte dies nicht m&ouml;glich sein, haben Sie die
						M&ouml;glichkeit, die Verpackung an uns zu schicken. Die Verpackungen werden von uns wieder verwendet oder
						gem&auml;&szlig; der Bestimmungen der Verpackungsverordnung entsorgt.
					</p>
					<p>Stand der AGB und Hinweis: Januar 2023</p>
					<p>
						Widerrufsformular&nbsp;
						<br />
						<br />
						Wenn Sie den Vertrag widerrufen wollen, dann f&uuml;llen Sie bitte dieses Formular aus und senden Sie es
						zur&uuml;ck.&nbsp;
						<br />
						An:&nbsp;
						<br />
						Daniel Thiel
						<br />
						Friedrich-Ebert-Straße 143
						<br />
						34119 Kassel
						<br />
						anfragen@daniel-thiel.com
					</p>
					<p>
						- Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag &uuml;ber den Kauf der
						folgenden Waren (*)/ die Erbringung der folgenden Dienstleistung (*)
					</p>
					<p>- Bestellt am (______________)/erhalten am (_____________)</p>
					<p>- Name des/der Verbraucher(s) _________________________________</p>
					<p>
						- Anschrift des/der Verbraucher(s) _______________________________ ________________________________
						________________________________
					</p>
					<p>- Unterschrift des/der Verbraucher(s)_____________________________(nur bei Mitteilung auf Papier)</p>
					<p>- Datum:____________________</p>
					<p>
						_______________
						<br />
						(*) Unzutreffendes streichen.
					</p>
				</div>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		lautenschlagerLogo: file(relativePath: { eq: "webdesign-kassel-lautenschlager-logo.png" }) {
			publicURL
		}
	}
`;
